<script>
export default {
    props: {
        payments: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            loading: {
                markAsPaid: false
            },
            modal: {
                markAsPaid: false
            },
            toDoMarkAsPaid: null,
            isModalActive: false,
            monto: '', // Variable para el monto
            bolivares: "",
            idTransaction: "",
            arrayCuentas:[]
        }
    },
    methods: {
        writeToClipboard(text) {
            navigator.clipboard.writeText(text)
                .then(() => {
                    this.$buefy.toast.open({
                        message: 'Texto copiado al portapapeles',
                        type: 'is-success'
                    })
                })
                .catch(() => {
                    this.$buefy.toast.open({
                        message: 'Error al copiar el texto al portapapeles',
                        type: 'is-danger'
                    })
                })
        },
        sendMarkAsPaid(id) {
            this.loading.markAsPaid = true

            this.$http(`/api/crypto-pocket/mark-as-paid/${id}`, {
                method: 'post',
                headers: {
                    Authorization: `${this.$root.auth.type} ${this.$root.auth.token}`
                }
            })
                .then((res) => {
                    const payment = this.payments.find((item) => item.id === res.data.id)

                    if (payment !== undefined) {
                        for (const [key, value] of Object.entries(res.data)) {
                            payment[key] = value
                        }
                    }

                    this.$buefy.toast.open({
                        message: 'Pago marcado como valido',
                        type: 'is-success'
                    })
                })
                .catch(() => {
                    this.$buefy.toast.open({
                        message: 'Error al marcar el pago como valido',
                        type: 'is-danger'
                    })
                })
                .finally(() => {
                    this.loading.markAsPaid = false
                    this.modal.markAsPaid = false
                })
        },
        openMarkAsPaidModal(id) {
            this.toDoMarkAsPaid = id
            this.modal.markAsPaid = true
        },
        openModalEditar(data) {
            this.isModalActive = true;
            this.monto = data.amount.toFixed(2);
            this.bolivares = data.bolivares.toFixed(2);
            this.idTransaction = data.id;

            this.$http.get('/api/banckTransation/'+ data.id, {
                headers: {
                    Authorization: `${this.$root.auth.type} ${this.$root.auth.token}`
                }
            })
            .then((res) => {
                
                var i;
                var arrayData = res.data.data;
               
                for(i=0; i < arrayData.length; i++){

                    arrayData[i].amount = arrayData[i].amount.toFixed(2);

                }


                this.arrayCuentas = arrayData;
            })
            .catch((err) => {
                console.log(err);
            });

            
        },

        formatMonto() {

            this.monto = this.monto.replace(/[^0-9.]/g, '');

            // Asegurarse de que solo haya un punto decimal
            let pointCount = (this.monto.match(/\./g) || []).length;

            if (pointCount > 1) {
                // Si hay más de un punto, eliminar el último
                this.monto = this.monto.substring(0, this.monto.lastIndexOf('.')) + this.monto.substring(this.monto.lastIndexOf('.') + 1);
            }

            // Asegurarse de que solo haya dos decimales
            if (this.monto.indexOf('.') !== -1) {
                let parts = this.monto.split('.');
                parts[1] = parts[1].substring(0, 2); // Limitar a dos decimales
                this.monto = parts.join('.');
            }

            // Si el valor es solo un punto decimal, eliminarlo
            if (this.monto === '.') {
                this.monto = '';
            }
        },

        formatArrayMonto(index) {

            this.arrayCuentas[index].amount = this.arrayCuentas[index].amount.replace(/[^0-9.]/g, '');

            // Asegurarse de que solo haya un punto decimal
            let pointCount = (this.arrayCuentas[index].amount.match(/\./g) || []).length;

            if (pointCount > 1) {
                // Si hay más de un punto, eliminar el último
                this.arrayCuentas[index].amount = this.arrayCuentas[index].amount.substring(0, this.arrayCuentas[index].amount.lastIndexOf('.')) + this.arrayCuentas[index].amount.substring(this.arrayCuentas[index].amount.lastIndexOf('.') + 1);
            }

            // Asegurarse de que solo haya dos decimales
            if (this.arrayCuentas[index].amount.indexOf('.') !== -1) {
                let parts = this.arrayCuentas[index].amount.split('.');
                parts[1] = parts[1].substring(0, 2); // Limitar a dos decimales
                this.arrayCuentas[index].amount = parts.join('.');
            }

            // Si el valor es solo un punto decimal, eliminarlo
            if (this.arrayCuentas[index].amount === '.') {
                this.arrayCuentas[index].amount = '';
            }

        },

        formatBolivares() {
            this.bolivares = this.bolivares.replace(/[^0-9.]/g, '');

            // Asegurarse de que solo haya un punto decimal
            let pointCount = (this.bolivares.match(/\./g) || []).length;
            if (pointCount > 1) {
                // Si hay más de un punto, eliminar el último
                this.bolivares = this.bolivares.substring(0, this.bolivares.lastIndexOf('.')) + this.bolivares.substring(this.bolivares.lastIndexOf('.') + 1);
            }

            // Asegurarse de que solo haya dos decimales
            if (this.bolivares.indexOf('.') !== -1) {
                let parts = this.bolivares.split('.');
                parts[1] = parts[1].substring(0, 2); // Limitar a dos decimales
                this.bolivares = parts.join('.');
            }

            // Si el valor es solo un punto decimal, eliminarlo
            if (this.bolivares === '.') {
                this.bolivares = '';
            }
        },

        editarMonto() {

            //console.log(typeof this.arrayCuentas);

            const regex = /^\d+\.\d{2}$/;
            
            if(this.monto !== "" && this.bolivares !== ""){

                if (regex.test(this.monto) && regex.test(this.bolivares)) {

                    var data = {
                        "id": this.idTransaction,
                        "amount": this.monto,
                        "bolivares": this.bolivares
                    };


                    this.$http.post('/api/transaction/UpdateTransaction', data, {
                        headers: {
                            Authorization: `${this.$root.auth.type} ${this.$root.auth.token}`
                        }
                    })
                    .then((res) => {
                    

                        if(res.data.message === "Transaction updated successfully"){


                            this.$http.put('/api/updateBankTransaction/',this.arrayCuentas, {
                                headers: {
                                    Authorization: `${this.$root.auth.type} ${this.$root.auth.token}`
                                }
                            })
                            .then((res) => {

                               

                                if(res.data.status === "success"){
                                    alert("Montos Actualizados con éxito");
                                    window.location.reload();
                                }
                                    
                                
                            })
                            .catch((err) => {
                                console.log(err);
                            });



                        }
                        else{
                            alert("No se pudo actualizar los montos intentelos mas tarde.");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                        
                } else {
                    alert("Formatos de montos invalidos.")  // El formato no es válido
                }
  

            }
            else{

                alert("complete todos los campos por favor");

            }
            
        },
        

    },
    name: 'TransactionalTable'
}
</script>


<template>
    <div>
        <b-table
            :data="payments"
            bordered
            detailed
            detail-transition="fade"
            striped
            :row-class="({ status }) => status === 'payment_received' ? ['is-row-with-header'] : []"
        >
            <b-table-column
                field="id"
                label="ID"
                :width="50"
                sortable
                searchable
                :custom-search="(a, input) => a.id.toString().includes(input)"
                v-slot="{ row: { id, status, manual_approval } }"
            >
                <span class="has-text-weight-bold">
                    #{{ id }}
                </span>
                
                <div
                    v-if="status === 'payment_received'"
                    class="has-background-success-light is-flex is-justify-content-center py-2"
                    style="position: absolute; width: 100%; top: 0; left: 0; height: 3rem; border-color: hsl(0, 0%, 86%); border-width: 1px 1px 0 1px; border-style: solid;"
                >
                    <b-tooltip
                        position="is-bottom"
                        :triggers="['click', 'focus']"
                        type="is-dark"
                        :auto-close="['outside', 'escape']"
                    >
                        <template #content>
                            <b>Por:</b>
                            <span v-if="manual_approval === null">
                                El Sistema
                            </span>
                            <span v-else>
                                {{ manual_approval.approver.name }} {{ manual_approval.approver.lastname }}
                            </span>

                            <br v-if="manual_approval !== null">

                            <b v-if="manual_approval !== null">A las:</b>
                            <span v-if="manual_approval !== null">
                                {{ $moment(manual_approval.approved_at).format('DD/MM/YYYY hh:mm:ss a') }}
                            </span>
                        </template>

                        <b-tag
                            :type="manual_approval === null ? 'is-dark' : 'is-primary'"
                            class="is-clickable"
                            title="Ver detalles"
                        >
                            <span v-if="manual_approval === null" style="user-select: none;">
                                Aprobado Automáticamente
                            </span>
                            <span v-else style="user-select: none;">
                                Aprobado Manualmente
                            </span>
                        </b-tag>
                    </b-tooltip>
                </div>
            </b-table-column>

           <b-table-column
                field="nombre"
                label="Nombre"
                
                sortable
                searchable
                :custom-search="(a, input) => {
                    return (a.transaction.user || {}).name.toLowerCase().includes(input) || (a.transaction.user || {}).lastname.toLowerCase().includes(input);
                }"
                v-slot="{ row }"  
            >
                <span
                    v-if="row.transaction.user !== undefined && row.transaction.user !== null"
                    class="has-text-weight-bold"
                >
                    {{ row.transaction.user.name }} {{ row.transaction.user.lastname }}
                </span>
            </b-table-column>

            <b-table-column
                field="concepto"
                label="Concepto"
                
                sortable
                searchable
                :custom-search="(a, input) => {
                    return (a.data.concept || '').toString().includes(input);
                }"
                v-slot="{ row }"  
            >
                <span class="has-text-weight-bold">
                    {{ row.data.concept ? row.data.concept : '-' }}  
                </span>
            </b-table-column>

            

            <b-table-column
                field="transaction_id"
                label="Transacción Nº"
                sortable
                searchable
                :custom-search="(a, input) => a.transaction_id.toString().includes(input)"
                v-slot="{ row: { transaction_id } }"
            >
                <span class="has-text-weight-bold">
                    #{{ transaction_id }}
                </span>
            </b-table-column>

            <b-table-column
                field="type"
                label="Tipo"
                :width="100"
                sortable
                searchable
                v-slot="{ row: { type } }"
            >
                <b-tag
                    v-if="type === 'psd2'"
                    type="is-info"
                >
                    PSD2
                </b-tag>

                <b-tag
                    v-else-if="type === 'transfer'"
                    type="is-dark"
                >
                    Transferencia
                </b-tag>
            </b-table-column>

           
            <b-table-column
                field="amount"
                label="Monto"
                :width="80"
                sortable
                searchable
                :custom-search="(a, input) => a.transaction.amount.toString().includes(input)"
                v-slot="{ row }"
            >

                 <span v-if="typeof row.transaction.amount === 'number'">
                    {{ row.transaction.amount.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} €
                </span>

                <span
                    v-else
                    class="has-text-grey"
                >
                    Desconocido
                </span>

                
            </b-table-column>

            <b-table-column
                field="status"
                label="Estado"
                sortable
                v-slot="{ row: { status } }"
            >
                <b-tag
                    v-if="status === 'pending'"
                    type="is-warning"
                >
                    Pendiente
                </b-tag>

                <b-tag
                    v-else-if="status === 'payment_received'"
                    type="is-success"
                >
                    Pago Valido
                </b-tag>

                <b-tag
                    v-else-if="status === 'payment_invalid'"
                    type="is-danger"
                >
                    Pago Invalido
                </b-tag>

                <b-tag
                    v-else-if="status === 'holder_conflict'"
                    type="is-warning"
                >
                    Validar Titular
                </b-tag>

                <b-tag
                    v-else-if="status === 'expired'"
                    type="is-danger"
                >
                    Tiempo Expirado
                </b-tag>
            </b-table-column>

            <b-table-column
                field="created_at"
                label="Fecha"
                searchable
                sortable
                :custom-sort="(a, b, isAsc) => {
                    const dateA = new Date(a.created_at).getTime()
                    const dateB = new Date(b.created_at).getTime()

                    return isAsc ? dateA - dateB : dateB - dateA
                }"
                v-slot="{ row: { created_at } }"
            >
                <span class="is-size-7">
                    {{ $moment(created_at).format('DD/MM/YYYY hh:mm:ss a') }}
                </span>
            </b-table-column>

            <b-table-column
                label=""
                cell-class="vertical-align-middle"
                v-slot="{ row: { id, status, transaction } }"
            >
                <b-tooltip
                    v-if="status !== 'payment_received'"
                    label="Marcar como Pago Valido"
                    position="is-left"
                    animated
                    type="is-dark"
                >
                    <b-button
                        class="m-1"
                        icon-right="check"
                        size="is-small"
                        type="is-success"
                        :loading="loading.markAsPaid"
                        @click="openMarkAsPaidModal(id)"
                    />

                </b-tooltip>

                <b-tooltip

                    v-if="status !== 'payment_received'"
                    label="Editar Monto"
                    position="is-left"
                    animated
                    type="is-dark"
                >

                    <b-button
                        class="m-1"
                        icon-right="fas fa-pencil-alt"
                        size="is-small"
                        type="is-info" 
                        @click="openModalEditar(transaction)"
                    />

                </b-tooltip>
            </b-table-column>

            <template #detail="{ row: { transaction, data, holder, holder_validation_percentage } }">
                <div class="columns">
                    <div class="column">
                        <h3 class="subtitle has-text-primary is-6">
                            Transacción
                        </h3>

                        <ul>
                            <li class="mb-0 has-text-primary-dark is-size-7">
                                Referencia:
                            </li>
                            <li class="mb-4">
                                #{{ transaction.id }}
                            </li>

                            <li class="mb-0 has-text-primary-dark is-size-7">
                                Usuario:
                            </li>
                            <li class="mb-4">
                                <span v-if="transaction.user !== undefined && transaction.user !== null">
                                    {{ transaction.user.name }} {{ transaction.user.lastname }}
                                </span>

                                <span
                                    v-else
                                    class="has-text-grey"
                                >
                                    Desconocido
                                </span>
                            </li>

                            <li class="mb-0 has-text-primary-dark is-size-7">
                                Monto:
                            </li>
                            <li class="mb-4">
                                <span v-if="typeof transaction.amount === 'number'">
                                    {{ transaction.amount.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} €
                                </span>

                                <span
                                    v-else
                                    class="has-text-grey"
                                >
                                    Desconocido
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div class="column">
                        <h3 class="subtitle has-text-primary is-6">
                            Detalles
                        </h3>

                        <ul>
                            <li
                                v-if="data !== undefined && data !== null && data.iban !== null"
                                class="mb-0 has-text-primary-dark is-size-7"
                            >
                                IBAN:
                            </li>
                            <li
                                v-if="data !== undefined && data !== null && data.iban !== null"
                                class="mb-4"
                            >
                                {{ data.iban }}
                            </li>

                            <li
                                v-if="data !== undefined && data !== null && data.bic !== null"
                                class="mb-0 has-text-primary-dark is-size-7"
                            >
                                BIC:
                            </li>
                            <li
                                v-if="data !== undefined && data !== null && data.bic !== null"
                                class="mb-4"
                            >
                                {{ data.bic }}
                            </li>

                            <li
                                v-if="data !== undefined && data !== null && data.concept !== null"
                                class="mb-0 has-text-primary-dark is-size-7"
                            >
                                Concepto:
                            </li>
                            <li
                                v-if="data !== undefined && data !== null && data.concept !== null"
                                class="mb-4"
                            >
                                <b-taglist>
                                    <b-tag
                                        type="is-dark"
                                        style="border-bottom-right-radius: 0; border-top-right-radius: 0; margin-right: 0;"
                                    >
                                        {{ data.concept }}
                                    </b-tag>

                                    <b-tag
                                        type="is-primary"
                                        style="border-bottom-left-radius: 0; border-top-left-radius: 0; margin-left: 0; cursor: pointer;"
                                        @click="writeToClipboard(data.concept)"
                                    >
                                        <b-icon
                                            icon="copy"
                                        />
                                    </b-tag>
                                </b-taglist>
                            </li>

                            <li
                                v-if="data !== undefined && data !== null && data.payment_url !== null"
                                class="mb-0 has-text-primary-dark is-size-7"
                            >
                                URL del Pago:
                            </li>
                            <li
                                v-if="data !== undefined && data !== null && data.payment_url !== null"
                                class="mb-4"
                            >
                                <b-taglist>
                                    <b-tag
                                        type="is-dark"
                                        style="border-bottom-right-radius: 0; border-top-right-radius: 0; margin-right: 0;"
                                    >
                                        <span
                                            class="text-desborder is-inline-block"
                                            style="max-width: 260px; line-height: .70rem;"
                                        >
                                            {{ data.payment_url }}
                                        </span>
                                    </b-tag>

                                    <b-tag
                                        type="is-primary"
                                        style="border-bottom-left-radius: 0; border-top-left-radius: 0; margin-left: 0; cursor: pointer;"
                                        @click="writeToClipboard(data.payment_url)"
                                    >
                                        <b-icon
                                            icon="copy"
                                        />
                                    </b-tag>
                                </b-taglist>
                            </li>

                            <li
                                v-if="holder !== undefined && holder !== null"
                                class="mb-0 has-text-primary-dark is-size-7"
                            >
                                Titular:
                            </li>
                            <li
                                v-if="holder !== undefined && holder !== null"
                                class="mb-4"
                            >
                                {{ holder }}
                            </li>

                            <li
                                v-if="holder_validation_percentage !== undefined && holder_validation_percentage !== null"
                                class="mb-0 has-text-primary-dark is-size-7"
                            >
                                Porcentaje de Validación del Titular:
                            </li>
                            <li
                                v-if="holder_validation_percentage !== undefined && holder_validation_percentage !== null"
                                class="mb-4"
                            >
                                {{ holder_validation_percentage }}%
                            </li>

                            <li
                                v-if="(data === undefined || data === null) && (holder === undefined || holder === null)"
                                class="has-text-grey has-text-centered"
                            >
                                No hay detalles del pago
                            </li>
                        </ul>
                    </div>
                </div>
            </template>

            <template #empty>
                <p class="has-text-centered is-italic has-text-grey py-4">
                    No hay pagos para mostrar
                </p>
            </template>
        </b-table>

        <b-modal
            scroll="clip"
            :width="420"
            v-model="modal.markAsPaid"
        >
            <div class="box">
                <div class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
                    <b-icon
                        icon="question-circle"
                        size="is-large"
                        custom-class="is-size-1 has-text-warning is-block"
                    />

                    <p
                        class="has-text-centered is-size-5 has-text-grey py-4 mt-4"
                    >
                        ¿Estás seguro de marcar este pago como valido?
                    </p>

                    <div class="is-flex is-justify-content-space-between">
                        <b-button
                            label="Cancelar"
                            rounded
                            type="is-danger"
                            class="mr-2"
                            @click="modal.markAsPaid = false"
                        />

                        <b-button
                            label="Aceptar"
                            rounded
                            type="is-success"
                            :loading="loading.markAsPaid"
                            @click="sendMarkAsPaid(toDoMarkAsPaid)"
                        />
                    </div>
                </div>
            </div>
        </b-modal>

        <b-modal  v-model="isModalActive" :active.sync="isModalActive" :width="400">
            
            <div class="has-background-white m-5">

                <h3 class="title is-4">Editar Monto</h3>

                <small style="color: gray;">Los montos deben cumplir con este formato. Ejemplo:100.00</small>
            
                <div class="field">
                    <label class="label">Monto</label>
                    <div class="control">
                        <input
                            class="input"
                            type="text"
                            v-model="monto"
                            placeholder="Ingresa el monto"
                            @input="formatMonto"
                        />
                    </div>
                </div>

                <div class="field">
                    <label class="label">Bolivares</label>
                    <div class="control">
                        <input
                            class="input"
                            type="text"
                            v-model="bolivares"
                            placeholder="Ingresa el monto"
                            @input="formatBolivares"
                        />
                    </div>
                </div>

                <div class="field" v-for="(cuenta, index) in arrayCuentas" :key="index">
                    <label class="label" :for="'input_' + index">Cuenta {{ index + 1 }}:</label>
                    <input
                        class="input"
                        :id="'input_' + index"
                        type="text"
                        v-model="arrayCuentas[index].amount" 
                        :placeholder="'Cuenta ' + (index + 1)"
                        @input="formatArrayMonto(index)"
                    />
                </div>
                

                <div class="buttons">
                    <button class="button is-success" @click="editarMonto">Editar</button>
                    <button class="button" @click="isModalActive = false">Cancelar</button>
                </div>

            </div>
            
            
        </b-modal>




    </div>
</template>

<style lang="scss">
.vertical-align-middle {
    vertical-align: middle !important;
}

.is-row-with-header {
    position: relative !important;
    padding-top: 4rem;

    @media screen and (min-width: 768px) {
        & td {
            padding-top: 4rem !important;
        }
    }
}
</style>
